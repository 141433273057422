<template>
  <div class="DetailsPage">
    <div class="DetailsPage-left">
      <div class="DetailsPage-left-header">
        <div>当前位置 ：</div>
        <div><router-link to="/index">首页</router-link></div>
        <div class="gang" v-show="pathtext != '/index'">/</div>
        <div v-show="pathtext != '/index'">
          <router-link :to="pathtext">{{ pathtextname }}</router-link>
        </div>
        <div class="gang">/</div>
        <div>详情正文</div>
      </div>
      <div class="DetailsPage-left-main">
        <div class="title">{{ detailsdata.title }}</div>
        <div class="Article-information" v-if="detailsdata">
          <div v-if="type == 2">
            <img class="iconfont" :src="imgIcon.imgPlace" />
            <div v-if="detailsdata.author">{{ detailsdata.author }}</div>
            <div v-else>德泰中研（转载）</div>
          </div>
          <div>
            <!-- <p class="author-name" v-if="detailsdata.author">
                    {{ detailsdata.author }}
                  </p> -->
            <img class="iconfont" :src="imgIcon.imgTime" />
            {{ detailsdata.addTime }}
          </div>

          <div>
            <i class="iconfont icon-yanjing"></i> {{ detailsdata.click }}
          </div>
          <!-- <div class="play">
             <el-button type="primary" size="small" plain>立即播放</el-button>
            
          </div> -->
        </div>
        <div v-if="type == 2">
          <div class="text-statement">
            {{ detailsdata.zhaiyao }}
          </div>
          <div class="main-text" v-html="detailsdata.content"></div>
        </div>

        <div class="main-body" v-if="type == 3">
          <div class="main-body-header">
            <div class="newmessage">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-a-zu333"></use>
              </svg>
              供需详情
            </div>
          </div>
          <div class="main-body-content" v-html="detailsdata.content"></div>
          <div class="main-body-header">
            <div class="newmessage">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-a-zu333"></use>
              </svg>
              联系方式
            </div>
          </div>
          <div class="main-body-content">
            <div v-if="detailsdata.articleFields[2].fieldValue">
              联系人：{{ detailsdata.articleFields[2].fieldValue }}
            </div>
            <div v-if="detailsdata.articleFields[3].fieldValue">
              手机号：{{ detailsdata.articleFields[3].fieldValue }}
            </div>
            <div v-if="detailsdata.articleFields[4].fieldValue">
              邮箱：{{ detailsdata.articleFields[4].fieldValue }}
            </div>
          </div>
          <div class="text-operation">
            <!-- <div>
            分享至 <i class="iconfont icon-weixin1"></i>
            </div> -->
            <!-- <div><i class="iconfont icon-dianzan"></i> 赞</div>
          <div><i class="iconfont icon-xiai"></i> 收藏</div> -->
            <!-- <div @click="dianzan(detailsdata.id)">
            
              <img :src="imgIcon.imgZan" />
              <span class="count">{{ detailsdata.likeCount }}</span>
            </div> -->
            <div @click="shoucang(detailsdata.id)" v-if="detailsdata.isFav">
              <img :src="imgIcon.imgCangTrue" />
              <span class="count">已收藏</span>
            </div>
            <div @click.stop="shoucang(detailsdata.id)" v-else>
              <!-- <i class="iconfont icon-xiai"></i> -->
              <img :src="imgIcon.imgCang" />
              <span class="count">收藏</span>
            </div>
          </div>
        </div>

        <div
          class="main-text"
          v-if="type == 6"
          v-html="detailsdata.content"
        ></div>
        <div
          class="main-text"
          v-if="type == 7"
          v-html="detailsdata.content"
        ></div>
        <!-- <div class="main-text" v-else v-html="detailsdata.content"></div> -->
      </div>
    </div>
    <div class="DetailsPage-right">
      <div class="consulting-header">
        <div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-a-zu339"></use>
          </svg>
          {{ pagedetails }}
        </div>
        <!-- <div>查看全部</div> -->
      </div>
      <div
        class="consulting-items"
        v-for="(item, index) in hotconsultlist"
        :key="index"
        @click="godetails(item, type)"
      >
        <!-- <div class="img-box" v-if="type == 2">
          <img :src="global.imgUrl + item.imgUrl" alt="" />
          <div class="date-message">
            <div class="date-title">{{ item.title }}</div>
          </div>
        </div> -->
        <div class="img-box" v-if="type == 3">
          <div
            :class="
              item.articleFields[0].fieldValue == '1'
                ? 'icon-point'
                : 'icon-need-point'
            "
          >
            {{ item.articleFields[0].fieldValue == "1" ? "供" : "需" }}
          </div>
          <p class="hot-demand-title">
            {{ item.title }}
          </p>
        </div>

       

        <div class="img-box" v-else>
          <img :src="global.imgUrl + item.imgUrl" alt="" />
          <div class="date-message">
            <div class="date-title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getdetails,
  gethotconsultinglist,
  gethotclasslist,
  gethotsupply,
  putLike,
  putFav,
} from "@/api";
import { imgObj } from "@/utils/const.js";
export default {
  name: "DetailsPage",
  data() {
    return {
      pathtext: "",
      pathtextname: "首页",
      id: 0,
      detailsdata: {},
      hotconsultlist: [],
      type: 2,
      pagedetails: "推荐资讯",
      imgIcon: imgObj,
    };
  },
  methods: {
    async start() {
      await getdetails(this.id).then((res) => {
        console.log("详情", res);
        this.detailsdata = res.data;
        this.detailsdata.addTime = this.detailsdata.addTime.slice(0, 10);
      });
      // await gethotconsultinglist().then((res) => {
      //   console.log("推荐资讯", res);
      //   this.hotconsultlist = res;
      // });
      switch (this.type) {
        case "2":
          console.log("2");
          gethotconsultinglist().then((res) => {
            this.hotconsultlist = res.data;
            this.pagedetails = "推荐资讯";
          });
          break;
        case "3":
          console.log("3");
          gethotsupply().then((res) => {
            this.hotconsultlist = res.data;
            console.log(this.hotconsultlist);
            this.pagedetails = "热门供需";
          });
          break;
        case "4":
          console.log("4");
          break;
        case "5":
          console.log("5");
          break;
        case "6":
          console.log("6");
          gethotclasslist(188).then((res) => {
            this.hotconsultlist = res.data;
            this.pagedetails = "热门活动";
          });
          break;
        case "7":
          console.log("7");
          gethotclasslist(189).then((res) => {
            this.hotconsultlist = res.data;
            this.pagedetails = "热门课程";
          });
          break;
        default:
          break;
      }
    },
    godetails(item, type) {
      console.log(item);
      localStorage.setItem("secondpath", JSON.stringify(this.$route.path));
      // this.$router.push(`/DetailsPage?id=${item.id}&type=${type}`);
      let routeData = this.$router.resolve({
        name: "DetailsPage",
        query: {
          id: item.id,
          type,
          title: item.title || item.Title,
        },
      });
      window.open(routeData.href, "_blank");
    },
    dianzan(articleId) {
      let username = window.localStorage.getItem("userName");
      console.log(username);
      if (username) {
        putLike(articleId).then((res) => {
          console.log(res);
          this.start();
        });
      } else {
        // this.$router.push('/login')
        this.$message({
          message: "请先登陆才能点赞",
          type: "warning",
        });
        //    this.router.replace({
        //   name: "/login",
        //   query: { redirect: router.currentRoute.name }
        // });
      }
    },
    shoucang(articleId) {
      let username = window.localStorage.getItem("userName");
      if (username) {
        putFav(articleId).then((res) => {
          console.log(res);
          this.start();
        });
      } else {
        this.$router.push("/login");
        this.$message({
          message: "您还没有登录，请先登陆才能收藏该文章",
          type: "warning",
        });
      }
    },
  },
  mounted() {
    this.pathtext = JSON.parse(localStorage.getItem("secondpath"));
    switch (this.pathtext) {
      case "/consulting":
        this.pathtextname = "资讯";
        break;
      case "/curriculum":
        this.pathtextname = "课程";
        break;
      case "/supplydemand":
        this.pathtextname = "供需";
        break;
      case "/activity":
        this.pathtextname = "活动";
        break;
      default:
        break;
    }
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    document.title = this.$route.query.title;
    this.start();
  },
};
</script>

<style lang="less" scoped>
.DetailsPage {
  width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  .DetailsPage-left {
    width: 100%;
    margin-right: 20px;
    .DetailsPage-left-header {
      display: flex;
      background: white;
      height: 56px;
      line-height: 56px;
      margin-bottom: 20px;
      div {
        margin: 0 5px;

        a {
          color: #c1c1c1;
        }
      }
      .gang {
        color: #c1c1c1;
      }
      div:nth-child(1) {
        margin-left: 20px;
      }
      div:nth-child(4),
      :nth-child(2) {
        cursor: pointer;
      }
      :hover {
        color: #171717;
      }
    }
    .DetailsPage-left-main {
      background: white;
      padding: 20px;
      .title {
        font-size: 25px;
        color: #171717;
        line-height: 45px;
        font-weight: bold !important;
        font-family: "PINGFANG-REGULAR" !important;
      }
      .Article-information {
        margin: 10px 0;
        color: #ababab;
        display: flex;
        justify-content: space-between;
        .author-name {
          padding: 0.1875rem 0.625rem;
          line-height: 1.25rem;
          text-align: center;
          background: #f5f5f5;
          color: #314483;
          display: inline;
          margin-right: 10px;

          // border: 0.0625rem solid #314483;
          // margin-left: 0.625rem;
        }
        div:nth-child(1) {
          // display: flex;
          display: -webkit-box;
        }
        div {
          margin-right: 50px;
        }
        .iconfont {
          margin-right: 10px;
          color: #848484;
          height: 1rem;
        }
        display: flex;
        .play {
        }
      }
      .text-statement {
        background: #f6f6f6;
        padding: 20px;
        color: #888;
        line-height: 1.75rem;
      }
      .main-text {
        margin: 20px 0;
        line-height: 34px;
        /deep/section {
          color: #464646 !important;
          font-size: 16px !important;
          line-height: 1.75rem !important;
          font-family: "PINGFANG-MEDIUM" !important;
        }
        /deep/img {
          margin: 0 auto !important;
          // width: 100% !important;
        }
        /deep/p {
          color: #464646 !important;
          font-size: 16px !important;
          line-height: 1.75rem !important;
          font-family: "PINGFANG-MEDIUM" !important;
          span {
            color: #464646 !important;
            font-size: 16px !important;
            line-height: 1.75rem !important;
            font-family: "PINGFANG-MEDIUM" !important;
          }
        }
      }
      .main-body {
        margin: 20px 0;
        .main-body-header {
          background: white;
          display: flex;
          justify-content: space-between;
          padding: 20px 20px;
          border-bottom: 1px solid #e9e9e9;
          .newmessage {
            font-size: 20px;
            .icon {
              width: 18px;
              height: 18px;
            }
          }
        }
        .main-body-content {
          margin: 30px;
          /deep/p {
            color: #464646 !important;
            font-size: 1rem !important;
            line-height: 1.75rem !important;
            font-family: "PINGFANG-MEDIUM" !important;
          }
          /deep/div {
            color: #464646 !important;
            font-size: 1rem !important;
            line-height: 1.75rem !important;
            font-family: "PINGFANG-MEDIUM" !important;
          }
          /deep/span {
            color: #464646 !important;
            font-size: 1rem !important;
            line-height: 1.75rem !important;
            font-family: "PINGFANG-MEDIUM" !important;
          }
        }
      }
      .text-operation {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        // div:nth-child(1) {
        //   position: absolute;
        //   left: 0px;
        // }
        // .iconfont {
        //   font-size: 20px;
        //   color: #a8a8a8;
        //   margin-left: 10px;
        //   margin-right: 5px;
        //   cursor: pointer;
        // }
        // div {
        //   padding: 10px 20px;
        //   background: #f5f6f6;
        //   color: #314483;
        //   font-size: 14px;
        //   cursor: pointer;
        //   margin-right: 20px;
        //   span {
        //     margin-left: 5px;
        //   }
        // }
        div {
          padding: 10px 25px;
          background: #fff;
          color: #525252;
          font-size: 14px;
          margin-right: 20px;
          border: 1px solid #ededed;
          display: flex;
          cursor: pointer;
          align-items: flex-end;
          span {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .DetailsPage-right {
    width: 40%;
    min-width: 360px;
    .consulting-header {
      padding: 20px 20px;
      background: white;
      border-bottom: 1px solid #e9e9e9;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .icon {
        width: 16px;
        height: 16px;
      }
      div:nth-child(1) {
        font-size: 16px;
        font-weight: 400;
      }
      div:nth-child(2) {
        font-size: 16px;
        font-weight: 400;
        color: #9a9a9a;
      }
    }
    .consulting-items {
      padding: 20px;
      background: white;
      .img-box {
        position: relative;
        cursor: pointer;
        display: flex;
        width: 100%;
        img {
          width: 100%;
          height: 12rem;
        }
        .icon-point {
          background: #005fb4;
          color: white;
          // width: 16px;
          height: 15px;
          font-size: 11px;
          padding: 2px 4px;
          border-radius: 2px;
          margin-right: 10px;
          // margin-top: 4px;
        }
        .icon-need-point {
          background: #28b28b;
          color: white;
          width: 17px;
          height: 17px;
          font-size: 12px;
          padding: 2px 4px;
          border-radius: 2px;
          margin-right: 10px;
          margin-top: 4px;
        }
        .hot-demand-title {
          font-size: 15px;
          line-height: 20px;
          margin: 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }
     
      .date-message {
        bottom: 0px;
        width: 88%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        color: white;
        position: absolute;
        padding: 15px 20px;
        cursor: pointer;
        font-size: 14px;
        .date-title {
          overflow: hidden; /*超出部分隐藏*/
          white-space: nowrap; /*禁止换行*/
          text-overflow: ellipsis; /*省略号*/
          text-align: center;
        }
        .date-box {
          display: flex;
          justify-content: space-between;
          opacity: 0.63;
          padding-bottom: 5px;
          .iconfont {
            font-size: 12px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>